import React, { Fragment } from 'react';
import { Listbox as HuiListbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

const Listbox = ({ className, setSelected, selected, options }) => {
  console.log(selected);
  return (
    <div className={twMerge('w-fit text-base', className)}>
      <HuiListbox value={selected} onChange={setSelected}>
        <div className="relative mt-[4px]">
          <HuiListbox.Button className="relative w-full cursor-default rounded-lg bg-white py-1 pl-[12px] pr-5 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <span className="block truncate">{selected.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
              <ChevronUpDownIcon className="h-[20px] w-[20px] text-gray-400" aria-hidden="true" />
            </span>
          </HuiListbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HuiListbox.Options className="w-fit absolute mt-[4px] max-h-96 overflow-auto rounded-md bg-white py-[4px] text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option, i) => (
                <HuiListbox.Option
                  key={i}
                  className={({ active }) =>
                    `relative cursor-default select-none py-[4px] pl-5 pr-1 ${
                      active ? 'bg-[#F2ECE0]' : ''
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-[12px] text-bronze">
                          <CheckIcon className="h-[20px] w-[20px]" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </HuiListbox.Option>
              ))}
            </HuiListbox.Options>
          </Transition>
        </div>
      </HuiListbox>
    </div>
  );
};

export default Listbox;
