import React, { useState } from 'react';
import { uniqBy, sortBy } from 'lodash';
import Layout from 'src/components/layout';
import SEO from 'src/components/seo';
import { StoriesTable } from 'src/components/stories';
import Signup from 'src/components/Signup';
import Listbox from 'src/components/Listbox';

const DEFAULT_SELECTED = { id: null, label: 'all pilot locations' };

const Stories = ({ pageContext: { stories } }) => {
  const [selectedLocation, setSelectedLocation] = useState(DEFAULT_SELECTED);

  const filteredStories = React.useMemo(
    () =>
      uniqBy(stories, 'pilotLocation').map((x) => ({
        id: x.pilotLocation,
        label: x.pilotLocation,
      })),
    [stories],
  );

  return (
    <Layout>
      <SEO title="Stories" />
      <main className={`bg-tan pt-4 lg:pt-8 text-base`}>
        <div className="container px-4 lg:px-0 md:mx-auto relative z-10">
          <h1 className="text-[40px] font-[700] mb-3 w-full lg:w-1/2">Stories from participants</h1>
          <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between lg:mb-12">
            <p className="text-base font-[400] w-full lg:w-1/2 lg:mb-0">
              Storytellers are a diverse group of pilot participants who have volunteered to share
              their personal experiences in the program for a public audience. Spending data tells
              part of the story. But some of the most important stories are not in the numbers.
              Participant testimonies bring a diversity of lived experiences to the forefront of the
              conversation. The difficulties, challenges, and solutions they share help make sense
              of the data and are effective tools of narrative change.
            </p>
            <p className="mt-2 mb-8 flex flex-col sm:flex-row sm:items-center lg:my-0">
              Showing stories from{' '}
              <Listbox
                className="inline-block translate-y-[6px] min-w-[250px] sm:ml-1 sm:-mt-2"
                options={[DEFAULT_SELECTED, ...sortBy(filteredStories, 'label')]}
                selected={selectedLocation}
                setSelected={setSelectedLocation}
              />
            </p>
          </div>
        </div>
        <StoriesTable
          stories={
            selectedLocation.id
              ? stories.filter((x) => x.pilotLocation === selectedLocation.id)
              : stories
          }
        />
      </main>
      <Signup />
    </Layout>
  );
};

export default Stories;
