import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { KeyboardArrowUpIcon, KeyboardArrowDownIcon } from 'src/assets/svg';

const StoriesTable = ({ stories }) => {
  // sortKey: participantName | pilotLocation
  // sortDir: none | asc | desc
  const [sort, setSort] = useState({
    sortKey: 'pilotLocation',
    sortDir: 'asc',
  });

  const filteredStories =
    sort.sortKey && sort.sortDir && sort.sortDir !== 'none'
      ? [...stories].sort(function (a, b) {
          return sort.sortDir === 'asc'
            ? ('' + a[sort.sortKey]).localeCompare(b[sort.sortKey])
            : ('' + b[sort.sortKey]).localeCompare(a[sort.sortKey]);
        })
      : stories;

  const handleSortChange = (sortKey) => {
    if (sort.sortKey === sortKey) {
      if (sort.sortDir === 'none') {
        setSort({ sortKey, sortDir: 'asc' });
      } else if (sort.sortDir === 'asc') {
        setSort({ sortKey, sortDir: 'desc' });
      } else {
        setSort({ sortKey, sortDir: 'none' });
      }
    } else {
      setSort({ sortKey, sortDir: 'asc' });
    }
  };

  const renderSortIcon = (sortDir) => {
    return (
      <div className="flex flex-col justify-center items-center ml-1 gap-[2px]">
        {(sortDir === 'none' || sortDir === 'asc') && (
          <KeyboardArrowUpIcon className="w-[10px] h-[6px]" />
        )}
        {(sortDir === 'none' || sortDir === 'desc') && (
          <KeyboardArrowDownIcon className="w-[10px] h-[6px]" />
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto">
      <div className="w-full flex flex-col items-center px-0 sm:px-5 lg:px-0">
        {/* Header */}
        <div className="w-full px-[20px] lg:px-[40px] pb-3 bg-tan">
          <div className="w-full sm:flex items-start">
            <div className="w-0 lg:w-[136px]" />
            <div className="grow-0 sm:grow ml-0 lg:ml-5">
              <div className="w-full flex justify-center lg:justify-start gap-6 sm:gap-12 lg:gap-1">
                <div className="w-auto lg:w-2/5">
                  {/*<button
                    className="flex items-center text-[14px] font-[800] tracking-[1.8px]"
                    onClick={() => handleSortChange('participantName')}
                  >
                    PARTICIPANT(S)
                    {renderSortIcon(sort.sortKey === 'participantName' ? sort.sortDir : 'none')}
                  </button> */}
                </div>
                <div className="w-auto lg:w-2/5">
                  {/* <button
                    className="flex items-center text-[14px] font-[800] tracking-[1.8px]"
                    onClick={() => handleSortChange('pilotLocation')}
                  >
                    LOCATION
                    {renderSortIcon(sort.sortKey === 'pilotLocation' ? sort.sortDir : 'none')}
                </button> */}
                </div>
                <div className="hidden lg:block lg:w-1/5" />
              </div>
            </div>
          </div>
        </div>
        {/* List */}
        {(filteredStories || []).map((item, index) => (
          <div
            className="w-full px-[20px] lg:px-[40px] py-4 sm:py-5 bg-tan even:bg-brown lg:cursor-pointer hover:bg-[#F2ECE0]"
            key={`story-table-row-${index}`}
            onClick={() => navigate(`/stories/${item.slug}`)}
          >
            <div className="w-full sm:flex items-start">
              <div className="mx-auto md:mx-0 w-[240px] h-[240px] md:w-[136px] md:h-[136px] md:min-w-[136px]">
                <GatsbyImage
                  className="w-full h-full object-cover self-start"
                  image={getImage(item.thumb)}
                  alt={item.participantName}
                />
              </div>
              <div className="grow-0 sm:grow ml-0 sm:ml-5">
                <div className="w-full flex flex-col lg:flex-row py-4 sm:py-0 gap-1">
                  <p className="text-center sm:text-left text-[13px] lg:text-[14px] font-[800] leading-[16px] tracking-[1.8px] w-full lg:w-2/5">
                    {item.participantName}
                  </p>
                  <p className="text-center sm:text-left text-[13px] lg:text-[16px] font-[400] leading-[16px] w-full lg:w-2/5">
                    {item.pilotLocation}
                  </p>
                  <p className="text-center sm:text-left text-[13px] lg:text-[16px] font-[400] leading-[16px] w-full lg:w-1/5">
                    {new Date(item.date).toLocaleDateString(undefined, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}{' '}
                  </p>
                </div>
                <p className="text-center sm:text-left text-[16px] font-[400] mt-0 sm:mt-2 lg:mt-3 w-auto lg:w-[600px]">
                  {item.excerpt}
                </p>
              </div>
            </div>
            <div className="flex lg:hidden justify-center sm:justify-start mt-3">
              <Link to={`/stories/${item.slug}`}>
                <button className="text-white text-[13px] !font-[800] tracking-[1.2px] w-[85px] sm:w-[136px] h-[48px] bg-bronze">
                  VIEW
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoriesTable;
